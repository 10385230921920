import { useMemo, useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { uuid } from '@grrr/utils';
import { store, restore } from 'helpers/utils';
import { formatPayload } from 'helpers/legislations/legislation';
import { ButtonSet, Button,  Form, Tabs, Icon } from 'components';

import { CREATE_ROLE_CONTENT, UPDATE_LEGISLATION, UPDATE_ATTENTION_POINT } from 'configs/api-endpoints';
import { useQueryApi } from 'hooks';

import { useAppStateContext, useLegislationContext } from 'contexts';
import { General, Requirements, RoleSpecific } from './TabPages';
import {
  EDIT_TABS,
  REVIEW_STATE,
  CREATED_STATE,
  PUBLISHED_STATE,
  GENERAL_FILTER_DATA,
  JOB_ROLE_KEY,
  REQUIREMENT_ERROR_FIELDS,
} from 'configs/legislation/legislation';

import { Legislation } from 'hooks/interfaces';
import { EditProps, JobRoleContent } from './TabPages/interfaces';
import styles from './Edit.module.scss';

const CURRENT_FORM_DATA_ID = 'current-edit-form-data';
interface ErrorData {
  message: string;
  errors: { [key: string]: any };
  results: null;
}


const EditLegislation = ({
  legislation,
  user,
  isEditing,
  setIsEditing,
  ...props
}: EditProps) => {

  // Form reference
  const formRef = useRef<HTMLFormElement | null>(null);
  const tabRef = useRef<HTMLUListElement | null>(null);

  // Unique IDs to be used
  const generalFormId = useMemo(() => uuid(), []);
  const id = useMemo(() => uuid(), []);

  const { state, stateDispatch, stateActions } = useLegislationContext();
  const { showToast } = useAppStateContext();

  const savedFormData = restore(CURRENT_FORM_DATA_ID, { permanent: false });
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [inputType, setInputType] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isDecline, setIsDecline] = useState(false);
  const [errorIsHidden, setErrorIsHidden] = useState(false);
  const [filters, setFilters] = useState<any[]>(state.filters);
  const [direction, setDirection] = useState<'next' | 'previous' >('next');
  const [formValues, setFormValues] = useState<{ [key: string]: any }>({});
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const [currentLegislationData, setCurrentLegislationData] = useState<Legislation>(isEditing ? legislation : {} as Legislation);
  const jobRoles = useMemo(() => state.filters.find((f) => f.label === JOB_ROLE_KEY)?.data || [], [state.filters]);

  const permission = useMemo(() => {
    const isApprover = user.is_approver;
    const isPreparer = user.is_preparer;
    return isApprover ? 'approver' : isPreparer ? 'preparer' : 'others';
  }, [user.is_approver, user.is_preparer]);


  const { post: createRoleContent } = useQueryApi(CREATE_ROLE_CONTENT);
  const { patch: updateRoleContent } = useQueryApi(UPDATE_ATTENTION_POINT);
  const { patch: update } = useQueryApi({
    ...UPDATE_LEGISLATION,
    endpoint: `${UPDATE_LEGISLATION.endpoint}${legislation.identifier}/`,
  });


  const { mutate: createJobRole, isError } = createRoleContent();
  const { mutate: updateJobRole, isError: isJobRoleUpdateError } = updateRoleContent();
  const { data: legislationData, mutate: updateLegislation, isSuccess, isError: isLegislationError, error } = update();
  const [errorData, setErrorData] = useState<ErrorData | null>(null);


  /**
   * Initially set the legislation data for the editing state
   */
  useEffect(() => {
    if (legislation?.identifier && isEditing) {
      setCurrentLegislationData(legislation);
      setFilters(state.filters);
      !isError && !isLegislationError && setFormValues({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legislation?.identifier, state.filters, isError, isLegislationError]);


  /**
   * Update the current legislation data when the update fails
   */
  useEffect(() => {
    if (savedFormData && Object.keys(savedFormData).length) {
      if ((isError || isLegislationError) && Object.keys(savedFormData).length !== Object.keys(currentLegislationData).length && error) {
        setCurrentLegislationData((prev) => ({ ...prev, ...savedFormData }));
        setErrorData(JSON.parse(error?.message || "{}"));
      }
    }

  }, [currentLegislationData, error, isError, isLegislationError, savedFormData]);


  /**
   * Check if form is valid.
   */
  const isFormValid = () => {
    return formRef?.current?.checkValidity();
  };


  /**
   * Handle tab click.
   */
  const tabClickHandler = (index: number) => {
    if (selectedIndex > Number(index)) {
      setDirection('previous');
    } else {
      setDirection('next');
    }
    setSelectedIndex(Number(index));
  };


  /**
   * Handle going next in the edit steps/pages.
   */
  const nextClickHandler = (e: any, d: 'next' | 'previous') => {
    e.preventDefault();
    setDirection(d);

    if (d === 'next') {
      if (selectedIndex === 3) {
        return;
      }
      setSelectedIndex(selectedIndex + 1);
    } else if (d === 'previous') {
      if (selectedIndex === 1) {
        return;
      }
      setSelectedIndex(selectedIndex - 1);
    }
  };


  /**
   * Handle Form input changes.
   */
  const onFormInputChange = (e: any, isChecked?: boolean) => {
    if (!formRef.current || !isEditing) return;

    const target = e.target;
    const input = e.target?.previousElementSibling?.querySelector('input');
    const formData = new FormData(formRef.current);
    const dataUpdated: { [key: string]: any } = Object.fromEntries(formData.entries());

    // for the issuing jurisdiction
    if (input && isChecked) {
      dataUpdated[input.name] = input.value;
    }

    // Remove any checkbox entries in formValues that are not in formData (i.e., unchecked)
    if (target?.type === 'checkbox' && !target?.checked) {
      delete dataUpdated[target.name];
    }

    if (input?.type === 'checkbox' && !isChecked) {
      delete dataUpdated[input.name];
    }

    setInputType(target?.type); // Track the input type

    setFormValues((prev: { [key: string]: any }) => {
      const updatedValues: { [key: string]: any } = { ...prev, ...dataUpdated };

      // Remove unchecked checkbox entries from the updatedValues
      if (target?.type === 'checkbox' && !target?.checked) {
        delete updatedValues[target.name];
      }
      if (input?.type === 'checkbox' && !isChecked) {
        delete updatedValues[input?.name];
      }

      return {
      ...updatedValues,
      is_in_effect: dataUpdated.is_in_effect === 'Yes'
    }});
  };


  useEffect(() => {
    const cleanFieldName = (field: string) => {
      const trimmedField = field.split('requirements_')[1];
      const cleanedField = trimmedField.replace(/-\d+$/, '');
      return cleanedField;
    };

    if (Object.keys(formValues).length) {
      const isValid = isFormValid();
      const requiredFields = (Array.from(formRef.current?.querySelectorAll('input[data-input-error="true"], textarea[data-input-error="true"]') || []) as HTMLInputElement[]);
      const fieldNames = requiredFields.map((field) => field.name).map((f) => {
        if (f.includes('requirements')) {
          return cleanFieldName(f);
        }
        return f;
      });

      if (!isValid) {
        if (fieldNames.length) {
          setMissingFields(fieldNames.map((field) => REQUIREMENT_ERROR_FIELDS[field as keyof typeof REQUIREMENT_ERROR_FIELDS]));
        }
      } else {
        if (!fieldNames.length) {
          setMissingFields([]);
        }
      }
    }
  }, [formValues]);


  /**
   * Call the form input change when the special checkbox is clicked
   */
  const onCheckboxChangeHandler = (e: any, isChecked: boolean) => {
    onFormInputChange(e, isChecked);
  }

  /**
   * Update the current legislation data
   */
  useEffect(() => {
    if (!Object.keys(formValues).length || ['text', 'textarea'].includes(inputType)) {
      return;
    }

    // Format the payload
    const data = { data: formValues, legislation: currentLegislationData, jobRoles };
    const { legislation_requirements } = formatPayload(data);

    // Update the legislation data
    GENERAL_FILTER_DATA.forEach((key) => {
      const data = (legislation_requirements as any)[key];
      if (data) {
        const filteredData = data.map((item: any) => {
          return { ...item, label: item.name, identifier: item.name.toLowerCase().replace(/ /g, '_'),
            checked: true, is_approved: true,
          }
        });
        (legislation_requirements as any)[key] = filteredData;
      }
    });

    setCurrentLegislationData((prev) => ({ ...prev, ...legislation_requirements }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, jobRoles]);


  /**
   * Make the request to update the Jobrole simultaneously
   * @param jobRoles
   * @returns void
   */
  const saveAllJobRoleContent = (jobRoles: JobRoleContent[]) => {
    if (!Object.keys(jobRoles || {})?.length) {
      !isError && !isLegislationError && setIsEditing(false);
      return Promise.resolve({ errors: [], results: [] });
    }

    return Promise.all(
      jobRoles.map((role: any) => {
        if (!role.identifier) {
          createJobRole({ ...role })
        } else {
          const { identifier, legislation, ...rest } = role;
          const updateUrl = `${UPDATE_ATTENTION_POINT.endpoint}${identifier}/custom-update/`;
          updateJobRole({ ...rest, endpoint: updateUrl });
        }
      }
    ));
  };


  /**
   * Reset the form values/date
   */
  const resetFormData = () => {
    if (!isError && !isLegislationError) {
      setIsEditing(false);
      setSelectedIndex(1);
      setCurrentLegislationData({} as Legislation);
      setFormValues({});
      setMissingFields([]);
    }
    return;
  };


  /**
   * Update the role-content -- this is a better way to handle the
   * attention-points update since we do not want to get out of the request
   * loop if any of them should fail, we take one success as enough and can proceed further
   * Update JobRoleData
   * @param jobRolePayload
   */
  const updateJobRoleData = (jobRolePayload: any) => {
    if (!jobRolePayload.length) {
      return;
    }

    saveAllJobRoleContent(jobRolePayload)
      .then(() => { resetFormData() })
      .catch(() => { resetFormData() });
  };


  /**
   * Update the Legislation data
   * @param payload
   */
  const updateLegData = (payload: any) => {
    const finalPayload = {
      ...payload,
      effective_date: !(payload as any).effective_date
        ? null
        : (payload as any).effective_date,
    }

    // Saving for re-use in case of error
    store(CURRENT_FORM_DATA_ID, { ...currentLegislationData, ...finalPayload,  }, { permanent: false });
    updateLegislation({ ...finalPayload });
  };


  /**
   * Update the current Legislation data when the selectedIndex changes
   * This is to ensure that the data is updated when the user navigates to the next/previous tab
   */
  useEffect(() => {
    if (!Object.keys(formValues).length) {
      return;
    }

    // Format the payload
    const data = { data: formValues, legislation: currentLegislationData, jobRoles };
    const { legislation_requirements, job_roles } = formatPayload(data);

    // setCurrentLegislationData((prev) => ({ ...prev, ...legislation_requirements }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, formValues]);


  /**
   * Get Edit Title
   */
  const editTitle = () => {
    return currentLegislationData.preparation_state === CREATED_STATE
      ? 'Edit Legislation'
      : currentLegislationData.preparation_state === REVIEW_STATE
        ? 'Review Legislation' : 'Preview Legislation'
  };

  /**
   * Show the correct button
   */
  const editButton = () => {
    return (
      <ButtonSet className={styles.root__buttongroup}>
        <Button
          variation='cancel'
          size="small"
          type='button'
          title={`Save changes for: ${(legislation as Legislation)?.name_generic || ''}`}
          onClick={() => {
            setIsEditing(false);
            setSelectedIndex(1);
            setCurrentLegislationData({} as Legislation);
            setFormValues({});
            setMissingFields([]);
          }}
        >
          Cancel
        </Button>
        <Button
          variation='secondary'
          size="small"
          type='button'
          title={`Save changes for: ${(legislation as Legislation)?.name_generic || ''}`}
          onClick={onSaveLegislationClickHandler}
          disabled={missingFields.length > 0}
          data-user-type={user.is_approver && legislation.preparation_state === REVIEW_STATE ? 'approver' : 'preparer'}
        >
          Save for later
        </Button>

        {user.is_approver && (legislation.preparation_state === REVIEW_STATE) ? (
          <Button
            variation='secondary-trans'
            size="small"
            type='button'
            title={`Decline: ${(legislation as Legislation).name_generic}`}
            disabled={!isFormValid() || legislation?.identifier === undefined}
            onClick={onDeclineLegislationClickHandler}
          >
            Decline
          </Button>
        ): null}

        <Button
          variation='primary'
          size="small"
          disabled={!isFormValid()}
          title={`${user.is_approver && legislation.preparation_state === REVIEW_STATE
            ? 'Approved for:'
            : 'Send '}'${(legislation as Legislation)?.name_generic || '' }' ${user.is_approver
              ? '' : 'for review'
            }`
          }
          data-user-type={user.is_approver && legislation.preparation_state === REVIEW_STATE ? 'approver' : 'preparer'}
          type='submit'
        >
          {user.is_approver && legislation.preparation_state === REVIEW_STATE ? 'Approve' : 'Send for review'}
        </Button>
      </ButtonSet>
    );
  };


  /**
   * Handle form submission for the legislation (send for review/approve)
   * Approvers can approve the legislation and send it for review
   * Preparers can only send the legislation for review
   * @e {Event}
   */
  const formSubmitHandler = (e: any) => {
    e.preventDefault();

    if (!isFormValid()) {
      return;
    }

    const payload = { data: formValues, legislation: currentLegislationData, jobRoles };
    const { legislation_requirements, job_roles } = formatPayload(payload,);

    const finalPayload = {
      ...legislation_requirements,
      preparation_state: user.is_approver && legislation.preparation_state === REVIEW_STATE
        ? PUBLISHED_STATE : REVIEW_STATE
    }

    // Patch the data to the API
    updateLegData(finalPayload);

    if (job_roles.length > 0) {
      updateJobRoleData(job_roles.filter((role: any) => (role.note !== undefined) && (role.note !== '')));
    }
  };


  /**
   * Save Legislation for future editing (for both preparers and approvers)
   * @param e
   */
  const onSaveLegislationClickHandler = (_e: any) => {
    const payload = { data: formValues, legislation: currentLegislationData, jobRoles };
    const { legislation_requirements, job_roles } = formatPayload(payload);
    const reqLength = Object.keys(legislation_requirements).length;

    // Patch the data to the API
    if (reqLength > 0 && !(reqLength === 1 && Object.keys(legislation_requirements).at(0) === 'is_in_effect')) {
      setIsSaving(true);
      updateLegData(legislation_requirements);
    }

    if (job_roles.length > 0) {
      updateJobRoleData(job_roles.filter((role: any) => (role.note !== undefined) && (role.note !== '')));
    }
  };


  /**
   * Decline a legislation for further review (only for approvers)
   * @param e
   */
  const onDeclineLegislationClickHandler = (_e: any) => {
    const { legislation_requirements } = formatPayload({ data: formValues, legislation: currentLegislationData, jobRoles });
    const finalPayload = { ...legislation_requirements, preparation_state: 'CREATED' };

    // Patch the data to the API
    setIsDecline(true);
    updateLegData(finalPayload);
  };


  /**
   * Handle the success/error messages
   */
  useEffect(() => {

    const getToastTitle = (updatedLegislation: Legislation) => {
      return isSaving
        ? 'Successfully Saved'
        : isDecline ? 'Legislation Not Approved'
          : user.is_approver && !([CREATED_STATE, REVIEW_STATE].includes(updatedLegislation.preparation_state))
            ? 'Successfully Published' : 'Sent for Review';
    };

    const getToastMessage = (updatedLegislation: Legislation) => {
      return isSaving
        ? 'Until this legislation is sent for review, it will remain active for further editing.'
        : isDecline ? 'Legislation has been sent back for further review/update.'
          : user.is_approver && !([CREATED_STATE, REVIEW_STATE].includes(updatedLegislation.preparation_state))
            ? 'Please be aware that this legislation is published and can no longer be edited.'
            : 'Please be aware that this legislation is now under review, the approver will check and finally publish/decline.';
    };

    if (isSuccess && !legislationData?.errors?.length) {
      setIsEditing(false);

      showToast({
        title: getToastTitle(legislationData?.results),
        message: getToastMessage(legislationData?.results),
        active: true,
        type: 'message',
        persistent: false,
      });

      setIsDecline(false);
      setIsSaving(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permission, isSuccess]);


  /**
   * Handle the error messages in case of failure in the TOAST
   * This is to ensure that the user is aware of the error and can try again
   */
  useEffect(() => {
    const isApprove = permission === 'approver';

    if (isLegislationError || isError) {
      setCurrentLegislationData((prev) => ({ ...prev, ...savedFormData }));
      setSelectedIndex(selectedIndex);
      setIsEditing(true);

      showToast({
        title: 'Something went wrong',
        message: `Please try ${isSaving
            ? 'saving this legislation'
            : isApprove
              ? 'approving this legislation'
              : 'sending this legislation for review'}  again later.`,
        type: 'error',
        active: false,
        persistent: false,
        orientation: 'left',
      });

      setIsSaving(false);
      setIsDecline(false);
    }
  }, [isLegislationError, isError, permission, selectedIndex, setIsEditing, showToast, isSaving, savedFormData]);


  /**
   * Set the legislation data after successful update to the state
   * This is to ensure that the state is updated with the new data
   */
  useEffect(() => {
    if (isSuccess) {
      const finalLegislations = state.legislations.map((l) => {
        if (l.identifier === legislationData.results.identifier) {
          return legislationData.results;
        }
        return l;
      });
      stateDispatch(stateActions.initState({ legislations: finalLegislations || [], permission }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, legislationData, permission]);


  /**
   * Check if the required fields are filled in
   */
  useLayoutEffect(() => {
    // get all required fields
    const requiredFields = (Array.from(formRef.current?.querySelectorAll('input[required], textarea[required]') || []) as HTMLInputElement[]);

    if (!isFormValid() && Object.keys(formValues).length) {
      requiredFields.map((inputField) => {
        if (inputField.value.trim()) {
          inputField.removeAttribute('data-input-error');
          return;
        }
        inputField.setAttribute('data-input-error', 'true');
      });
    }

    if (isFormValid() && Object.keys(formValues).length) {
      requiredFields.map((inputField) => {
        inputField.removeAttribute('data-input-error');
      });
    }
  }, [formValues]);


  return (
    <section
      className={styles.root}
      hidden={!isEditing}
      data-main-content
      data-edit-content
      {...props}
    >
      <Form
        id={generalFormId}
        data={{}}
        onSubmit={formSubmitHandler}
        onChange={onFormInputChange}
        ref={formRef}
        {...props}
      >
        <header className={styles.root__header}>
          <div className={styles.root__headertop}>
            <h4>{editTitle()}</h4>
            {editButton()}
          </div>
          {isError || isLegislationError ? (
            <div className={styles.root__error} data-hidden={errorIsHidden}>
              <h3>Check the errors below</h3>
              {errorData?.errors ? Object.keys(errorData.errors || {})?.map((key, index) => {
                return (
                  <div key={index} data-error-list>
                    <h5>{index+1}: {key.replaceAll("_", " ")}</h5>
                    <ul>
                      {errorData.errors[key]?.map((error: any, i: number) => {
                        if (typeof error === 'string') {
                          return (
                            <li key={i}>
                              <p>{error}</p>
                            </li>
                          );
                        }

                        return Object.keys(error) && Object.keys(error || {})?.map((k, j) => {
                          return (
                            <li key={j}>
                              {error[k].length && error[k]?.map((e: string, l: number) => {
                                return (
                                  <p key={l}><em>{e}----</em>&nbsp;{k.replaceAll('_', ' ')}</p>
                                );
                              })}
                            </li>
                          );
                        });
                      })}
                    </ul>
                  </div>
                );
              }): (
                <p>Please check for any missing/required field and try again.</p>
              )}
              <ButtonSet data-btn-set>
              <Button
                variation="primary"
                size='small'
                type="button"
                onClick={() => setErrorIsHidden(true)}
              >
                <Icon name="cross-fill" color="currentColor"/>
              </Button>
            </ButtonSet>
            </div>

          ) : null}

          <div className={styles.root__tabs}>
            <Tabs
              tabSelected={selectedIndex}
              options={EDIT_TABS}
              onTabClick={tabClickHandler}
              data-tabs
              type='pages'
              align='left'
              ref={tabRef}
            />
          </div>
          {missingFields?.length ? (
            <div className={styles.root__missingfields} data-show={missingFields.length > 0}>
              <h6>Missing fields:&nbsp;</h6>
              <ul>
                {missingFields.map((field, index) => (
                  <li key={index}><small>{field}</small></li>
                ))}
              </ul>
            </div>
          ) : null}
        </header>

        {/* Page 1: */}
          <General
            filters={filters}
            data-direction={direction}
            selectedIndex={selectedIndex}
            data={currentLegislationData}
            onNotApplicable={setFormValues}
            onCheckboxChange={onCheckboxChangeHandler}
          />

        {/* Page 2: */}
          <Requirements
            filters={filters}
            selectedIndex={selectedIndex}
            isEditing={isEditing}
            data={currentLegislationData}
            onNotApplicable={setFormValues}
            setMissingFields={setMissingFields}
          />

        {/* Page 3: */}
          <RoleSpecific
            user={user}
            filters={filters}
            showToast={showToast}
            data-direction={direction}
            selectedIndex={selectedIndex}
            // onInputChange={onInputChange}
            data={currentLegislationData}
          />

        <ButtonSet className={styles.root__buttonset}>
          {selectedIndex === 1 ? (
            <>&nbsp;</>
          ) : (
            <Button
              variation="secondary"
              size='small'
              type="button"
              onClick={(e: any) => nextClickHandler(e, 'previous')}
            >
              Previous
            </Button>
          )}
          {selectedIndex === 3 ? (
            <>&nbsp;</>
          ) : (
            <Button
              variation="primary"
              size='small'
              type="button"
              onClick={(e: any) => nextClickHandler(e, 'next')}
            >
              Next
            </Button>
          )}
        </ButtonSet>
      </Form>
    </section>
  );

};

export default EditLegislation;
