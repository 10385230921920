import { Children, Fragment, useEffect, useMemo, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { uuid } from '@grrr/utils';
import { getFormValues } from 'helpers/utils';
import { formatProjectPayload } from 'helpers/projects/projects';
import { applyFilter } from 'hooks/reducers/helpers';

import { Button, IconComponent, TopContent, ProjectCard, Modal, Tabs, ButtonSet, Form, Loader } from 'components';
import { ClientDetails, AdminSetup, ContentConfiguration } from './Components';
import EditLegislation from 'pages/Legislation/Admin/EditContent/EditLegislation';

import useQueryApi from 'hooks/useQueryApi';
import { useAppStateContext, useLegislationContext, useUserContext } from 'contexts';
import { withLegislationHocs } from 'contexts/LegislationContext';
import { GET_CLIENT_PROJECTS, CREATE_CLIENT_PROJECT } from 'configs/api-endpoints';
import { DEFAULT_APPROVED_FILTER } from 'configs/legislation/legislation';
import { UserProfile } from 'hooks/interfaces';
import { Category, Legislation } from 'hooks/interfaces/legislation.interface';

import EmptyProjects from './EmptyProjects';
import {
  PROJECT_CREATE_TABS,
  PROJECTS_CREATE_MODAL,
  PROJECT_CREATE_FORM_ID,
} from 'configs/project/project';
import styles from './Projects.module.scss';


interface Project {
  associated_legislations: Legislation[];
  client_members: [];
  created_at: string;
  description: string;
  domain: string;
  identifier: string;
  is_published: boolean;
  name: string;
  team_members: UserProfile[];
  starting_date: string;
  updated_at: string;
}

interface GroupProject {
  label: string;
  projects: Project[];
}


const Projects = () => {
  const navigate = useNavigate();

  const tabRef = useRef<HTMLUListElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);

  // Unique IDs to be used
  const generalFormId = useMemo(() => uuid(), []);
  const id = useMemo(() => uuid(), []);

  const { user } = useUserContext();
  const { state } = useLegislationContext();
  const { showToast } = useAppStateContext();

  const [groupedProjects, setGroupedProjects] = useState<GroupProject[]>([{ label: '', projects: [] }]);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [formValues, setFormValues] = useState<any>({});
  const [selectedFilters, setSelectedFilters] = useState<Category>({ ...DEFAULT_APPROVED_FILTER });
  const [teamMembers, setTeamMembers] = useState<string[]>([]);
  const [legislations, setLegislations] = useState<Legislation[]>(state.legislations);
  const [direction, setDirection] = useState<'next' | 'previous' >('next');
  const [groupingProjects, setGroupingProjects] = useState<boolean>(true);


  const { get: getProjects } = useQueryApi(GET_CLIENT_PROJECTS);
  const { post: createClientProject } = useQueryApi(CREATE_CLIENT_PROJECT);
  const { data: projectsData, error: accessError, isLoading: isAccessLoading } = getProjects(null);
  const { data, mutate: createProject, isError, isSuccess } = createClientProject();


  /**
   * Update projects state when projectsData changes
   */
  useEffect(() => {
    if (projectsData && !projectsData?.errors?.length) {
      const groupData = [
        { label: 'Published', projects: [] },
        { label: 'Draft', projects: [] },
      ];

      // Group projects by status (Published, Draft)
      projectsData.results?.forEach((project: Project) => {
        const status = project.is_published ? 'Published' : 'Draft';
        const group = groupData.find((group: GroupProject) => group.label === status);

        if (group) {
          const projectExists = group.projects.find((p: Project) => p.identifier === project.identifier);
          if (!projectExists) {
            (group as GroupProject).projects.push(project);
          }
        }
      });
      setGroupedProjects(groupData);
      setGroupingProjects(false);
    }
  }, [projectsData]);


  /**
   * Handle tab click.
   */
  const tabClickHandler = (index: number) => {
    if (selectedIndex > Number(index)) {
      setDirection('previous');
    } else {
      setDirection('next');
    }
    setSelectedIndex(Number(index));
  };


  /**
   * Mange the team members added to the project payload
   * @param teamMembers
   */
  const updateTeamMembers = (teamMembers: string[]) => {
    setTeamMembers(teamMembers);
  };


  /**
   * Update the filters when the user selects a filter.
   * @param filters
   * @param event
  */
  const updateFilters = (event: any, isInputChecked: boolean) => {
    if (!event?.target) return;

    const input = event.target.previousElementSibling.querySelector('input');
    const isCustomCheckbox = input?.dataset.customCheckbox;
    const category = isCustomCheckbox ? 'issuing_jurisdiction' : event.target.dataset.category;
    const identifier = isCustomCheckbox && input
      ? state?.filters?.find(f => f.label === category)?.data?.find(d => d.name === input?.value)?.identifier
      : event.target.dataset.identifier;

    // for the custom checkboxes
    if (input?.type === 'checkbox' && isCustomCheckbox) {
      if (isInputChecked && identifier) {
        if (!selectedFilters[category as keyof Category].includes(identifier)) {
          setSelectedFilters((prev) => ({
            ...prev,
            [category]: [...prev[category as keyof Category], identifier]
          }));
        }
      }

      if (!isInputChecked && identifier) {
        setSelectedFilters((prev) => ({
          ...prev,
          [category]: prev[category as keyof Category].filter((id: string) => id !== identifier)
        }));
      }
      return;
    }

    // for the default checkboxes
    if (event.target.type === 'checkbox' && !isCustomCheckbox && identifier) {
      if (category && (event.target.checked)) {
        if (!selectedFilters[category as keyof Category].includes(identifier)) {
          setSelectedFilters((prev) => ({
            ...prev,
            [category]: [...prev[category as keyof Category], identifier]
          }));
        }
      }
      if (!(event.target.checked)) {
        setSelectedFilters((prev) => ({
          ...prev,
          [category]: prev[category as keyof Category].filter((id: string) => id !== identifier)
        }));
      }
      return;
    }
  };


  /**
   * Update the legislations when the selected filters change.
   */
  useEffect(() => {
    const filteredLegislations = applyFilter(state.legislations, selectedFilters) as Legislation[];
    setLegislations(filteredLegislations);
  }, [selectedFilters, state.legislations]);


  /**
   * Handle Form input changes.
   */
  const onFormInputChange = (event: any, isChecked: boolean) => {
    if (!formRef.current) return;

    // Update filters
    updateFilters(event, isChecked);
    const formData = getFormValues(formRef.current, event);

    // Update form values
    setFormValues((prev: any) => ({
      ...prev,
      ...formData,
      team_members: teamMembers
    }));
  };


  /**
   * Update the form values when the special checkbox changes.
   * @param e
   * @param isChecked
   */
  const onCheckboxChangeHandler = (e: any, isChecked: boolean) => {
    onFormInputChange(e, isChecked);
  }


  /**
   * Update form values when team members changes.
   */
  useEffect(() => {
    if (isFormUpdated) {
      onFormInputChange(undefined, false);
      setIsFormUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormUpdated]);


  /**
   * Handle form submission.
   */
  const formSubmitHandler = (e: any) => {
    e.preventDefault();

    // Get payload data
    const payload = formatProjectPayload(formValues, legislations, teamMembers);

    // Post to the project create endpoint to create a new project
    createProject(payload);
  };


  /**
   * Handle the project creation success or error.
   */
  useEffect(() => {
    if (isSuccess) {
      showToast({
        title: 'Project Created Successfully',
        message: `Please take not that project has been created successfully but can still be updated/edited by an admin.`,
        type: 'message',
        active: true,
        persistent: false,
      });
      navigate(`/projects/${data.identifier}/edit`);
    }

    if (isError) {
      showToast({
        title: 'Something went wrong',
        message: `Please be informed the project could not be created, try again later.`,
        type: 'error',
        active: true,
        persistent: true,
      });
    }

    setIsCreating(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isError, isSuccess]);


  /**
   * Handle going next in the project creation steps/tabs.
   */
  const paginationHandler = (e: any, d: 'next' | 'previous') => {
    e.preventDefault();
    setDirection(d);

    if (d === 'next') {
      if (selectedIndex === 3) {
        return;
      }
      setSelectedIndex(selectedIndex + 1);
    } else if (d === 'previous') {
      if (selectedIndex === 1) {
        return;
      }
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const alertContent = user.is_admin
  ? 'Please be aware that this is still under development and may be missing some features/functionalities.'
  : `Please be aware that this is still under development and things might change by the time we are done.`;

  return (
    <Fragment>

      {/* Top Content */}
      <TopContent isDetails>
        <Fragment>
          <h3>Projects</h3>
          <div data-add-btn>
            <p>Create tailored environments by selecting the sustainability legislations relevant to each client.</p>
            {user.is_admin ? (
              <Button variation='primary' onClick={() => setIsCreating(true)}>
              <IconComponent name="PlusFillIcon" />
              <span>New project</span>
            </Button>
            ) : null}
          </div>
        </Fragment>
      </TopContent>

      {/* Main Content */}
      <section className={styles.root} data-main-content>
        {!groupedProjects.length ? (
          <EmptyProjects />
        ) : (
          <article className={styles.root__projects}>
            {groupedProjects.length ? Children.toArray(groupedProjects.map((group, index: number) => (
              <Fragment key={`${group.label}-${index}`}>
                <h3 className={styles.root__project_status}>{group.label}</h3>

                <ul className={styles.root__list} key={`${group.label}-${index}`} data-is-empty={group.projects.length === 0}>
                  {group.projects.length ? group.projects.map((project: Project) => (
                    <ProjectCard
                      key={project.identifier}
                      project={project}
                    />
                  )) : (
                    <div>
                      {groupingProjects ? <Loader data-medium /> : (
                        <span>There are currently no project for the <strong><em>{group.label}</em></strong> group.</span>
                      )}
                    </div>
                  )}
                </ul>
              </Fragment>
            ))) : null}
          </article>
        )}
      </section>

      <Modal
        id={PROJECTS_CREATE_MODAL}
        isOpen={isCreating}
        onOpen={() => setIsCreating(true)}
        onClose={() => setIsCreating(false)}
        data-project-create
      >
        <header data-project-create-header>
          <Button
            variation='transparent'
            onClick={() => setIsCreating(false)}
            size='small'
          >
            <IconComponent name="CloseOutlineIcon" />
          </Button>
          <div>
            <h2>Create new project</h2>
            <p>Provide general details about the project. You can alter these after the project has been created.</p>
            <span data-required>Required Fields</span>
          </div>
        </header>

        <Tabs
          tabSelected={selectedIndex}
          options={PROJECT_CREATE_TABS}
          onTabClick={tabClickHandler}
          data-tabs
          ref={tabRef}
        />

        <Form
          id={`${PROJECT_CREATE_FORM_ID}-${generalFormId}`}
          data={{}}
          onSubmit={formSubmitHandler}
          onChange={onFormInputChange}
          ref={formRef}
        >
          {/* Client Details */}
          <ClientDetails selectedIndex={selectedIndex} direction={direction} />

          {/* Admin/Team setup */}
          <AdminSetup
            selectedIndex={selectedIndex}
            updateMembers={updateTeamMembers}
            setIsFormUpdated={setIsFormUpdated}
          />

          {/* Project Setup */}
          <ContentConfiguration
            selectedIndex={selectedIndex}
            filters={state.filters}
            onCheckboxChange={onCheckboxChangeHandler}
          />
        </Form>

        {/* Action Buttons */}
        <ButtonSet data-btn-set>
          <Button
            variation="transparent"
            onClick={(event: any) => {
              selectedIndex === 1
                ? setIsCreating(false)
                : paginationHandler(event, 'previous');
            }}
            disabled={false}
          >
            {selectedIndex === 1 ? 'Cancel' : 'Previous'}
          </Button>
          {selectedIndex < 3 ? (
            <Button
            type='button'
            onClick={(event: any) => paginationHandler(event, 'next')}
            value="Submit"
            // disabled={false}
          >
            {selectedIndex < 3 ? 'Next' : 'Submit'}
          </Button>
          ) : (
            <Button
              type="submit"
              aria-controls={`${PROJECT_CREATE_FORM_ID}-${generalFormId}`}
              form={`${PROJECT_CREATE_FORM_ID}-${generalFormId}`}
              value="Submit"
              disabled={false}
            >
              Submit
            </Button>
          )}
        </ButtonSet>
      </Modal>
    </Fragment>
  );

};

export default withLegislationHocs(Projects);
